/* Swiper Container */
.swiper {
  width: 100%;
  height: 100%;
}

/* Swiper Slide */
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--color-green);
  /* border: 1px solid rgba(255, 255, 255, 0.1); Subtle border */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); Add shadow */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Swiper Slide Image */
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* PC Images Container */
.pcimages {
  height: 40vh;
  width: 60vw;
  padding: 2vh;
  /* background: linear-gradient(90deg, pcimages0%, rgb(30, 29, 29) 100%); */
  transition: all 0.6s ease-in-out;
  overflow: hidden; /* Prevent overflowing when scaling */
}

/* Hover Effect on PC Images */
.pcimages:hover {
  transform: scale(1.2);
}

/* Responsive Image Styling */
.PCImage {
  height: 100%;
  width: 100%;
  object-fit: contain; /* Maintain aspect ratio */
}

/* Media Query for Medium Screens (Tablets, Small Laptops) */
@media (max-width: 800px) {
  .pcimages {
    padding: 1vh;
    width: 50vw;
  }
}

/* Media Query for Small Screens (Mobile Devices) */
@media (max-width: 600px) {
  .pcimages {
    padding: 1vh;
    width: 90vw; /* Utilize more screen width */
  }
}
