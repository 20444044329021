.spSessionHeading h2{
    background-color: var(--color-green);
    font-family:var(--font-headings);
    font-weight: 400;
    padding: 1vh 0;
    font-size: 1.5rem;
    margin: 4vh auto;
    text-align: center;
    color: black;
    width: 96%;
}

.main-card{
    width: 90%;
    margin:2vh auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
    grid-auto-rows: minmax(100px, auto);
}

.card{    
    padding: 4vh 2vh;
    margin: 10px;
    border: 1px solid var(--color-black);
    color: black;
    box-shadow: var(--color-black) 0px 2px 4px 0px, var(--color-white) 0px 1px 3px 1px;
    /* border-radius: 0.5rem 2rem; */
    transition: all 0.5s ease-in-out;
    min-height: 250px;   
    font-family: var(--font-headings);
} 
/* .card:nth-child(2n+1){
    background-color:var(--color-black);
}
.card:nth-child(2n){
    background-color:var(--color-green);
} */
.card:hover{
    color:black;
    transform: translateY(-12px);
    cursor: pointer;
}
.margin-less{
    margin-top:0px;
}
.card-link{
    text-decoration: none;
    color:black;
    font-size: 18px;
    /* font-weight: bold; */
}
/* .card-link:hover{
    color: white;
} */
.zero-margin{
    font-size: 18px;
    margin: 2vh 0 0 0;
}
.ul-zero-margin{
  margin: 1vh;
  font-family: var(--font-content);
  font-size: 16px;
}
.ul-zero-margin li{
  list-style: disc;
   
  }



/* adding media queries  */

/* @media(max-width:800px)
{
    .main-card{
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
    }
} */
