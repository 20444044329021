.year h1{
    margin-bottom: 2vh;
    font-family:var(--font-headings);
}

@media(max-width:800px)
{
   .year{
    width: 90%;
    margin: 2vh auto;
   }
}