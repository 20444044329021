@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,400;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,200;1,300;1,400;1,500&family=Lato:wght@300&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,900;1,200;1,300;1,400;1,600;1,800&family=Playfair+Display:wght@700&family=Poppins:ital,wght@0,200;0,300;0,400;1,100;1,200;1,300;1,400&family=Roboto:ital,wght@0,100;0,300;0,700;0,900;1,700;1,900&family=Rubik+Burned&family=Shadows+Into+Light&display=swap');


  
  .container {
    /* width: 95%; */
    margin: 0 auto; 
  }
  
  
  
  .container-title {
    text-align: center;
    padding: 0 !important;
    margin-bottom: 40px;
    font-size: 40px;
    color: black;
    font-weight: 600;
    line-height: 60px;
  }
  
  .card {
    border: 0;
   
  }
  
  .container-card {
    position: relative;
    border: 2px solid transparent;
    /* background-color: var(--color-bg); */
    background-color: white;
    background-clip: padding-box;
    /* border-radius: 45px; */
    padding: 20px;
    color:var(--color-green);
    min-width: 500px;
    img {
      margin-bottom: 32px;
    }
    transition: all 1s ease-in-out;
  }
  .bg-green-box:hover, .bg-white-box:hover{
/* background-color: var(--color-bg1); */
  }


  
  .bg-green-box,
  .bg-white-box
   {
    position: relative;
    transition: all 0.5s ease-in-out;
  }
  
  .bg-green-box::after,
  .bg-white-box::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    content: "";
    z-index: -1;
    /* border-radius: 45px; */
  }
/* 
  .bg-green-box:after {
    background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
  }
  
  .bg-white-box:after {
    background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
  }  */
  
  .card-title {
    font-weight: 600;
    color: var(--color-green);
    letter-spacing: -0.02em;
    line-height: 2rem;
    font-style: normal;
    font-size: 28px;
    padding-bottom: 8px;
    /* background-color:black; */
  }
  
  .card-description-title{
    font-weight: 400;
    color:var(--color-green) ;
   font-family: var(--font-headings);
    font-size: 1.2rem;
    display: inline;


  }
  .card-description {
    font-weight: 600;
    line-height: 2rem;
    /* color: hsla(0, 0%, 100%, 0.5); */
    color: var(--color-green);
    font-size: 1.2rem;
   font-family: car(--font-content);
    text-align: start;
    display: inline;
  }
   .date-description{
  
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  
  

  @media(max-width:800px)
  {
    
  .container-card {
    padding: 2rem;
    margin: 2vh 0;
    min-width: 90%;
  }
 
}

  @media(max-width:600px)
  {
    .date-description{
        gap: 0.5rem;
        flex-direction: column;
        align-items: start;
        margin-bottom: 1.2rem;
    }
    .card-description {
        line-height: 1.5rem;
      }
     
  }