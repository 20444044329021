@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,400;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,200;1,300;1,400;1,500&family=Lato:wght@300&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,900;1,200;1,300;1,400;1,600;1,800&family=Poppins:ital,wght@1,200;1,400&family=Roboto:ital,wght@0,700;0,900;1,700;1,900&family=Rubik+Burned&family=Shadows+Into+Light&display=swap');

.reFeeMain{
    width: 98%;
    margin: 10vh auto;
    /* background-color: red; */
    /* background: linear-gradient(71deg, #080509, #1a171c, #080509); */
   padding: 2vh;
   /* border-radius: 2rem 0.5rem; */
   border: 1px solid var(--color-green);
}
.reFeeMain h3{
    padding: 1vh 0 0 0;
    color: white;
    font-weight: 500;
    background-color: var(--color-green);
    padding: 1vh;
    font-weight: 600;
    text-align: center;
}
.reFeetd , .reFeetr{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    
}


/* .reFeetr:hover{
background-color: var(--color-green);
} */
.reFeeTable{
    border: 1px solid rgb(164, 164, 164);
    margin: 3vh 0;
}
.reFeetd h2{
    font-size: 1.1rem;
   border: 1px solid rgb(164, 164, 164);
   padding: 4px;
   padding-left: 8px;
   color: black;
   font-weight: 600;
   font-family: var(--font-headings);
   background-color: var(--color-green);
   color: white;
   
}
.reFeetr p{
    border: 1px solid rgb(164,164,164);
    padding: 8px;
    padding-left: 8px;
    color: black;
}

.reSpDiscount h1{
    font-size: 1.2rem;
   font-weight: 500;
    color: black;
}

@media (max-width:760px)
{
    .reFeetr:hover{
        background:none;
      }
   
  .reFeetd h2{
    width: 30vw;
    font-size:1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .reFeetr p{
    width: 30vw;
    font-size: 0.8rem;
  }
}
