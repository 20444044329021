@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,400;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,200;1,300;1,400;1,500&family=Lato:wght@300&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,900;1,200;1,300;1,400;1,600;1,800&family=Poppins:ital,wght@1,200;1,400&family=Roboto:ital,wght@0,700;0,900;1,700;1,900&family=Rubik+Burned&family=Shadows+Into+Light&display=swap');
.sponsorPara{
line-height:2vh;
font-family: var(--font-content);
font-weight: 400;
margin: 2vh 0;
padding: 3vh;
/* background: linear-gradient(71deg, #080509, #1a171c, #080509); */
/* border-radius: 2rem 0.5rem; */
border: 1px solid var(--color-bg2);
/* color:white !important; */
color:black;
}

.rightSection{
   height:fit-content;
   box-sizing: border-box;
   position:sticky;
   top:10rem;
}
.aboutMain h2{
   font-family:var(--font-headings);
}
#sponsorPara h3{
   color: var(--color-warning);
   font-family: var(--font-headings);
   font-weight: 500;
}
.abLeft h4{
   font-size: 1.1rem;
   font-weight: 500;
   color: var(--color-warning);
  font-family: var(--font-headings);
}


#sponsorGreeting{
   display: flex;
   flex-direction: column;
   gap: 1rem;
}
#sponsorGreeting a{
    font-size: 20px;
    color: rgb(4, 118, 232);
   font-weight: 700;
}


