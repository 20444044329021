.PaGeCommon{
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-content: center; */
    font-family: var(--font-content);
    /* color:black; */
}
.ScCardsSteering{
 background: white;
    padding: 3vh;
    /* border: 1px solid var(--color-success); */
    /* border-radius: 0.5rem 2rem; */
    color: black;
}
.patron{
    margin: 1vh 0;
}
.patron h2{
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-green);
    margin: 1vh 0;
}
.patronMain {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vh;
    margin-bottom: 5vh;
    align-items: stretch; /* Ensures all items stretch to the same height */
}

.patrondata {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    gap: 1vh;
    margin: 1vh 0;
    /* border-radius: 0.5rem 2rem; */
    border: 1px solid var(--color-green);
    padding: 4vh 1vw;
    color: black;
    box-shadow: var(--color-green) 0px 1px 2px 0px, var(--color-green1) 0px 1px 3px 1px;
    transition: all 0.4s ease-in-out;
    height: auto; /* Dynamically adjusts height */
    min-height: 150px; /* Set a minimum height to ensure consistent styling */
}

.patrondata:hover {
    transform: scale(1.03);
    cursor: pointer;
}

/* Media Queries */
@media (max-width: 1000px) {
    .patronMain {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 700px) {
    .patronMain {
        grid-template-columns: 1fr;
    }
}

.patrondata h1{
    font-size: 1.1rem;
    font-weight: 800;
    color: var(--color-black);
}
.patrondata p{
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--color-black);
}


/* adding media query  */


@media(max-width:1000px)
{
    .patrondata{
    height: 18vh;
    }
    .patronMain{
        grid-template-columns: repeat(2, 1fr);
     }

    
}

@media(max-width:700px)
{
    .patrondata h1{
        font-size: 1.1rem;
    }
    .patrondata p{
        font-size: 1rem;
    }
    .PaGeCommon{
        grid-template-columns: 1fr ;
    }
    .patrondata{
        height: 16vh;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        text-align: justify;
    }
    .patronMain{
        grid-template-columns: 1fr;
     }
}