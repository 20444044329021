:root {
    /* --color-primary: #6c63ff; */
    --color-success: #00bf8e;
    --color-warning: #f7c94b;
    --color-danger: #f75842;
    --color-danger-variant: rgba(247,88, 66, 0.4);
    --color-white: #ffffff;
    --color-light: rgba(255, 255, 255, 0.7);
    --color-black: #000;
    --color-bg: #080509;
    --color-bg1: #1a1a1a;
    --color-bg2: #6B6E70;
    --color-pink: rgb(214, 238, 170);
    --color-green1: #76bd22;
    --color-green: #006938;
    --color-green-primary:#13840e;
    --font-headings : 'Calibri';
    --font-content : 'Open Sans';
    
}
 
*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    /* list-style: none; */
    box-sizing: border-box;
    
}

body {
    background-color: var(--color-light);
    overflow-x: hidden;
  }


