@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,400;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,200;1,300;1,400;1,500&family=Lato:wght@300&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,900;1,200;1,300;1,400;1,600;1,800&family=Playfair+Display:wght@700&family=Poppins:ital,wght@0,300;0,400;1,200;1,300;1,400&family=Roboto:ital,wght@0,700;0,900;1,700;1,900&family=Rubik+Burned&family=Shadows+Into+Light&display=swap');

.conference{
    width: 96%;
    margin: 4vh  auto;
    font-family: var(--font-content);
}
.conference h1{
    background-color: var(--color-green);
    font-weight: 400;
    padding: 1vh 0;
    font-size: 1.5rem;
    margin: 4vh 0;
    text-align: center;
    color: white;
}

.coContainer{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 1rem;
    justify-content: center;
    /* align-items: center; */
}

.conference .coAddress{
    padding: 2rem;
    /* background: linear-gradient(71deg, #080509, #1a171c, #080509); */
    /* border-radius: 2rem 0.5rem; */
    border: 1px solid var(--color-success);
    
    color: black;
    margin: 2vh 0;

}
.conference .coAddress li{
    font-weight: 500;
    font-size: 1.2rem;
    color: black;
    list-style-type: none;
    padding: 0 2vh;
    font-family: var(--font-content);

}
.conference .coAddress .confVenueEmail{
    color:var(--color-warning);
    /* font-size: 1.2rem; */
}
.conference .coAddress h1{
    margin: 2vh 0;
    background-color: var(--color-green);
    color: white;
    padding: 1vh;
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-family: var(--font-headings);
    /* border-radius: 2rem 0.5rem; */
   border: 1px solid var(--color-success);
   

}
.coLeftImg{
  height: 65vh;
  width: 50vw;
  background-color: var(--color-bg1);
  /* border-radius: 2rem 0.5rem; */
  
}
.coLeftImg img{
    height: 100%;
    width: 100%;
    padding: 1vh;
  /* border-radius: 2rem 0.5rem; */

}

.coAddress h1, .coAddress h2{
    color: #000;
    font-family: var(--font-headings);

}
.coRight{
    /* border:1px solid black; */
    height:fit-content;
    box-sizing: border-box;
    position:sticky;
    top:10rem;
}
.coAddress h1{
    font-weight: 500;
}

@media(max-width:900px)
{
    .coLeftImg{
        height: 50vh;
        width: 90vw;
      }
  
.coContainer{
    grid-template-columns: 1fr;
}
    .coRight{
        margin: 4vh 0;
    }
}