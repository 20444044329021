.policyPage {
    margin-bottom: 2rem;
    line-height: 2rem;
    font-family: var(--font-content);
}

.policyContent {
    padding: 0rem 2rem;
    margin: 2rem;
}

.policyPageCard {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 2rem;
    border: 1px black solid;
    width: 100%;
    max-width: 1200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.policyPageCard h1 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.policySection {
    text-align: left;
    color: rgb(33, 37, 41);
    background-color: rgb(255, 255, 255);
    font-size: 18px;
    width: 100%;
}

/* Ensure list items show bullets or numbers */
.policySection ul {
    list-style-type: disc !important;
    margin-left: 20px !important;
    list-style-type: disc;
}

.policySection ol {
    list-style-type: decimal !important;
    margin-left: 20px !important;
}

/* Ensure list item markers are visible */
.policySection ul li, .policySection ol li {
    display: list-item !important;
    list-style: disc;
}

/* Responsive Design */
@media (max-width: 768px) {
    .policyContent {
        padding: 0rem 1rem;
        margin: 1rem;
    }

    .policyPageCard {
        margin: 1rem;
    }

    .policySection {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .policyContent {
        padding: 0rem 0.5rem;
        margin: 0.5rem;
    }

    .policyPageCard {
        margin: 0.5rem;
    }

    .policySection {
        font-size: 14px;
    }
}
