
.steering{
    width: 96%;
    margin: 4vh  auto;
    font-family: var(--font-content);
}
.steering .heading{
    background-color: var(--color-green);
    font-family: var(--font-headings);
    font-weight: 400;
    padding: 1vh 0;
    font-size: 1.5rem;
    margin: 4vh 0;
    text-align: center;
    color: white;
}
.stCommittee{
    display: grid;
    /* grid-template-columns:  1fr; */
    /* margin: 4vh 0; */
    gap: 20px; 
}
 


@media(max-width:1000px)
{
    .steering{
        width: 90%;
    }
    /* .stCommittee{
grid-template-columns: 1fr;
    } */
    .steering h2{
        text-align: center;
    }
}