.preConf{
    /* display: grid; */
    /* grid-template-columns: 3fr 1fr; */
    /* gap: 20px; */
    width: 95%;
    text-align: justify;
    margin: 2vh auto;
}
.pcYear{
    margin: 2vh auto;    
    width: 95%;
    color: var(--color-green);
    
}

.imagesPc{
    width: 95%;
    margin: 4vh auto;
}

/* media query  */
@media(max-width:800px)
{
   .preConf{
    grid-template-columns: 1fr;
    width: 90%;
   }
}