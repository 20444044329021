.pcLinks{
    font-family: var(--font-content);
    margin: 8vh 0;
}
.pcLinks h1{
    font-family:var(--font-headings);
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 4vh;
}
.pcLinkMain{
    display: grid;
    grid-template-columns: 1fr 2fr;
    border: 1px solid gray;
}
.pcLinkMain h2{
    font-family:var(--font-headings);
    font-size: 1.2rem;
    padding: 1vh;
}
.pcLinkMain p{
    font-family: var(--font-content);
    border-left: 1px solid gray;
    padding: 1vh;
    font-size: 1rem;
}