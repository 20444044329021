.poster{
  width: 100%;
  height: 90vh;
}

@media (max-width:700px) {
  .poster{
    height: 25vh;
  }
}
