.keynoteHeading{
    background-color: var(--color-green);
    font-weight: 400;
    font-family: var(--font-headings);
    padding: 1vh 0;
    font-size: 1.5rem;
    margin: 4vh 0;
    text-align: center;
    color: white;
}
.academicPartner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
}
.logoImg{
  width:300px;
  height: 150px;
  object-fit: contain;
  margin:10px;
}
