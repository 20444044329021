@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,400;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,200;1,300;1,400;1,500&family=Lato:wght@300&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,900;1,200;1,300;1,400;1,600;1,800&family=Poppins:ital,wght@1,200;1,400&family=Roboto:ital,wght@0,700;0,900;1,700;1,900&family=Rubik+Burned&family=Shadows+Into+Light&display=swap");

a {
  text-decoration: none;
}
.conference {
  color: black;
}

.conference .ccontainer {
  width: 96%;
  margin: auto;
  font-family: var(--font-content);
}
.ccontainer .heading h1 {
  font-family: var(--font-headings);
  font-size: 22px;
  font-weight: 600;
  background-color:  var(--color-green) ;
  text-align: center;
  padding: 0.5rem 0;
  width: 100%;
}

.ccontainer .about {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 4vh;
}

.about .Labout {
  font-size: 1.1rem;
  line-height: 4vh;
  font-family: var(--font-content);
}
.about .Labout p {
  /* color: #fff; */
  /* margin-top: -40px; */
  font-weight: 500;
  text-align: justify;
  /* opacity: 0.7; */
}
.Labout .submissionLinkhead {
  margin: 4vh 0;
}
.Labout .submissionLinkhead a {
  color: white;
  /* border-radius: 0.7rem; */
  /* background: linear-gradient(to left, var(--color-green), var(--color-green1)); */
  padding: 2vh;
  font-family: -var(--font-heading);
  color: white;
  /* border: 1px solid var(--color-green1); */
  /* background-color:var(--color-green); */
  transition: all 0.3s ease-in-out;
}
.Labout .submissionLinkhead a:hover {
  /* background: linear-gradient(to left, var(--color-green1), var(--color-green)); */
}
.Labout .submissionLinkhead a:active {
  color: var(--color-pink);
}

.Labout .aboutLink:hover {
  /* background-color: var(--color-bg2); */
}

/* downloads  */
.Docard {
  margin: 0 auto;
  /* background: linear-gradient(71deg, #080509, #1a171c, #080509); */
  z-index: 1;
  border: 1px solid var(--color-green);
  /* background-color: var(--color-green-primary); */
  padding: 1rem;
  opacity: 1;
  color:black;
}

.tools {
  display: flex;
  align-items: center;
  padding: 9px;
}

.circle {
  padding: 0 4px;
}

.dobox {
  display: inline-block;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 1px;
  /* border-radius: 50%; */
}

/* .red {
  background-color: #ff605c;
}

.yellow {
  background-color: #ffbd44;
}

.green {
  background-color: #00ca4e;
} */

.Rabout {
  margin: -4vh 0;
  /* display: flex;
  flex-direction: column;
  height: 50%; */
}

.Rabout h1 {
  font-size: 22px;
  font-weight: 600;
  background-color: var(--color-green);
  text-align: center;
  padding: 1vh 0;
}
.Rabout .dwnldSection {
  display: flex;
  flex-direction: column;
  padding: 1vh 2vh;
}
.Rabout .dwnldSection p {
  margin-bottom: 2vh;
}
.Rabout .dwnldSection a {
  font-weight: 400;
  color: black;
  font-family: var(--font-headings);
  font-size: 1.1rem;
  transition: all 0.2s ease-in-out;
}
.Rabout .dwnldSection a:hover {
  color: var(--color-green);
}

.Labout .corange {
  padding: 1vh;
  margin: 1vh 0;
  color: black;
  font-weight:800 !important;
  /* font-weight: bold; */
  font-size: 1.1rem;
}


@media (max-width: 800px) {
  .ccontainer .about {
    grid-template-columns: 1fr;
  }
}
