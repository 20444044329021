.footer{
  /* background-color: #006938; */
  /* border-top: 1px solid black; */
    color: white;
    padding: 4vh 0;
    margin: auto;
    /* background-color: green; */
    /* background-color:#14315e;
    background-color:#005e63;
    background-color:#e14504;
    background-color:#9bbeac; */
    /* background-color:white;  */
    /* border-radius:100px 100px 0px 0px; */

    /* background-color:rgb(89 ,44 ,130); */
    background-color:#3a3c39;
    /* background-color:rgb(155 ,190 ,172); */
    font-family: var(--font-content);
}
.footerLogo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
.logoImg{
    width: 20rem;
    margin:10px;
    aspect-ratio:4;
    /* color:white; */
    filter: invert(1) brightness(2);
}

 .footer .f_lower{
    width: 95%;
    margin: 0 auto;
}
.fmain{
   display: grid;
   grid-template-columns: repeat(3,1fr);
   justify-content: center;
   gap: 1rem;
 
}

.fleft, .socialMedia, .links{
    padding: 4vh;
    
}
.fleft h1, .socialMedia h1, .links h1{
    font-weight: 700;
    font-size: 1.5rem;
    color: white;
    margin: 1vh 0;
}
.socialMedia{
   justify-self: center;
}
.socialMedia .social{
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    font-size:1.5rem;
    gap: 1rem;

}
.fleft, .fright{
    justify-self: center;

}
.socialMedia .social a{
    color: white;
    /* color: black; */
    font-weight: 400;
}
.socialMedia .social a:hover{
    color: var(--color-green);
}


.fright .links{
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.fright .links p{
/* color: black; */
color: white;
font-weight: 400;
    cursor: pointer;
}
 
.fright .links p:hover{
    color: var(--color-green);
}

.line{
    margin: 1vh 0;
}

.f_lower{
    /* background-color: red; */
    font-weight: 400;
    font-size: 18px;
    text-align: center;
}

.f_lower h3{
    font-weight: 400;
    font-size: 1.1rem;
}


@media(max-width:760px)
{
    .fmain{
        grid-template-columns: repeat(1,1fr);      
     }

     .footer .f_lower{
        width: 93%;
    }
    
}


