@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,400;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,200;1,300;1,400;1,500&family=Lato:wght@300&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,900;1,200;1,300;1,400;1,600;1,800&family=Poppins:ital,wght@1,200;1,400&family=Roboto:ital,wght@0,700;0,900;1,700;1,900&family=Rubik+Burned&family=Shadows+Into+Light&display=swap');

.cfp{
    font-family: var(--font-content);
    color: white;
    width: 96%;
    margin: 2vh auto;
   
}
.cfp h2{
    background-color: var(--color-green);
    font-family: var(--font-headings);
    font-weight: 400;
    padding: 1vh 0;
    font-size: 1.5rem;
    margin: 4vh 0;
    text-align: center;
}
.cfp ul li{
    list-style-type: circle;
    color: var(--color-black);
    padding: 1vh 0;
    margin: 0 4vh;
    font-weight: 500;
}



.bold{
    font-weight:400;
}

.bold1{
    font-weight: 600;
    margin: 4vh 0 1.5vh 0;
    background-color: var(--color-green);
    padding: 1vh ;
    color: white;
    

}
.call_desc p{
    font-family: var(--font-content);
    font-size: 1rem;
    line-height: 1.5rem;

}
.Call_con h5{
    font-family: var(--font-headings);
    font-weight: 500;
    font-size: 1.2rem;
    padding: 1vh 0;
    text-align: center;
    background-color: var(--color-green);
}
.cfp_info {
    background:  var(--color-green);
    padding: 3vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 2vh 0;
    border: 1px solid var(--color-bg2);
}
.cfp_info li{
    list-style: square;
    line-height: 1.4rem;
    text-align: justify;
   font-family: var(--font-content);
   font-size: 1.1rem;
   color: v;

}
.imp_dates .dates {
    font-family: var(--font-content);
}

.Call_con{
   display: grid;
    gap: 2rem;
    grid-template-columns: 2fr 2fr;
}
.callpaperEnd{
    font-size: 1rem;
    margin: 2vh 0;
    line-height: 1.4rem;
    font-weight: 400;
    background:  var(--color-green);
   padding: 3vh;
   border: 1px solid var(--color-bg2);
}
.callpaperEnd p{
    font-size: 1.1rem;
}
.callpaperEnd p a{
    color: var(--color-white);
}
.callpaperEnd p span{
    color: var(--color-white);

}
.cpaperColor{
    color: var(--color-white);
    font-weight: 500;
}
.call_desc{
    text-align: justify;
    font-family: var(--font-content);
  margin: 2vh 0;
}

@media(max-width: 800px)  {

   .Call_con{
    grid-template-columns: 1fr;
   }
   .cfp ul li{
    margin: 0 4vh;
}
.cfp{
    font-family: var(--font-content);
    color: white;
    width: 96%;
    /* margin: 2vh 6vh; */
   
}

    
}
