.payPal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  color: black;
    gap: 2vh;
  
}



.payPal h1{
    font-size: 1.1rem;
    font-weight: 600;
}
.payPal p{
    font-size: 1rem;
    font-weight: 400;
}

/* button  */
.submissionLinkheadRazor{
    margin: 2vh 0;
}
 .submissionLinkheadRazor a{
    color: white;
   /* border-radius: 0.7rem; */
    background:  var(--color-green);
    padding: 1vh;
    font-family: var(--font-content);
    color: white;

}






/* adding media query  */
@media(max-width:900px)
{
    .payPal p{
    padding: 1rem;
    }
}